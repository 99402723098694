import axios from 'axios';
import { cookies } from '../utils';

const auth = cookies.get('auth');
const url = window.location.hostname;
let API_URL:string;

if(url === 'wellnessme.ru')
{
    API_URL = 'https://client.wellnessme.ru/api/v1/'
} else {
    API_URL = 'https://client.playground.sysdyn.ru/api/v1/';
}


export const userEventsService = (actionId: string) =>
  axios.post(
    `${API_URL}user-events`,
    {
      actionId,
    },
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
